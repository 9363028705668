import axios from "@/plugins/axios";
import qs from "qs";

export const getAllVacancies = async (pagination) => {
  const query = qs.stringify({
    pagination,
    populate: "*",
    localizations: "*",
  });

  const response = await axios.get(`/vacancies?${query}`);
  return response?.data;
};
export const getVacancy = async (id) => {
  const query = qs.stringify({
    populate: {
      cities: {
        populate: {
          localizations: "*",
        },
      },
      job_titles: {
        populate: {
          localizations: "*",
        },
      },
      job_type: {
        populate: {
          localizations: "*",
        },
      },
      key_skills: {
        localizations: {
          populate: "*",
        },
      },
      localizations: "*",
    },
    localizations: "*",
  });

  const response = await axios.get(`/vacancies/${id}?${query}`);
  return response?.data?.data;
};

export const getFilteredVacancies = async (filters, pagination, excludeId) => {
  const queryFilters = { filters: {} };
  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    };
  }
  if (filters?.city && filters?.city?.id != 0) {
    queryFilters.filters.cities = {
      id: {
        $eq: filters.city.id,
      },
    };
  }
  if (filters?.job_type && filters?.job_type?.id != 0) {
    queryFilters.filters.job_type = {
      id: {
        $eq: filters.job_type.id,
      },
    };
  }
  if (filters?.job_title && filters?.job_title?.id != 0) {
    queryFilters.filters.job_titles = {
      id: {
        $eq: filters.job_title.id,
      },
    };
  }
  // if (!filters?.position && !filters?.city && !filters?.jobType) {
  //   queryFilters.filters = {};
  // }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 6,
      },
      populate: {
        image: "*",
        localizations: "*",
        cities: "*",
      },
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/vacancies?${query}`);

  return response?.data;
};

export const getJobTitles = async () => {
  const query = qs.stringify({
    populate: "*",
    localizations: "*",
  });

  const response = await axios.get(`/job-titles?${query}`);
  return response?.data?.data;
};

export const getJobTypes = async () => {
  const query = qs.stringify({
    populate: "*",
    localizations: "*",
  });

  const response = await axios.get(`/job-types?${query}`);
  return response?.data?.data;
};

export const sendReply = async (data) => {
  const response = await axios.post(`/vacancy-replies`, data);
  return response;
};
