<template>
  <div class="career-vacancy__filter" :class="{ 'is-active': active }">
    <!-- Filter 1 -->
    <vacancy-select
      v-if="citiesClone"
      title="city"
      :items="citiesClone"
      subtitle="allCities"
      field="name"
      v-model="selectedCity"
    />
    <div v-else class="loader-mini__container">
      <div class="loader-mini"></div>
    </div>

    <!-- Filter 2 -->
    <vacancy-select
      v-if="jobTypesClone"
      :title="'positionType'"
      :items="jobTypesClone"
      subtitle="all"
      field="title"
      v-model="jobType"
    />
    <div v-else class="loader-mini__container">
      <div class="loader-mini"></div>
    </div>
    <!-- Filter 3 -->
    <vacancy-select
      :title="'position'"
      v-if="jobTitlesClone"
      :items="jobTitlesClone"
      subtitle="allPosition"
      field="title"
      v-model="jobTitle"
    />
    <div v-else class="loader-mini__container">
      <div class="loader-mini"></div>
    </div>

    <button class="career-vacancy__button-search" @click="searchFilteredVac">
      {{ $t("toFind") }}
    </button>

    <button class="career-vacancy__button-reset" @click="resetSearchFiltered">
      {{ $t("resetAllFilters") }}
    </button>
  </div>
</template>

<script>
import { getJobTitles, getJobTypes } from "@/api/vacancy";
import { getAllCities } from "@/api";
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/vacancy";
export default {
  name: "VacancyFilter",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VacancySelect: () => import("@/components/vacancy/VacancySelect.vue"),
  },
  data() {
    return {
      selectedCity: null,
      jobType: null,
      jobTitle: null,
      citiesClone: null,
      jobTypesClone: null,
      jobTitlesClone: null,
    };
  },
  created() {
    getJobTitles().then((jobTitle) => {
      this.jobTitlesClone = [
        { attributes: { title: "allPosition" }, id: 0 },
        ...jobTitle,
      ];
    });
    getJobTypes().then((jobTypes) => {
      this.jobTypesClone = [
        { attributes: { title: "all" }, id: 0 },
        ...jobTypes,
      ];
    });
    getAllCities().then((cities) => {
      this.citiesClone = [
        { attributes: { name: "allCities" }, id: 0 },
        ...cities,
      ];
    });
  },
  mounted() {},
  methods: {
    ...mapActions("vacancyModule", {
      resetFilter: actionTypes.deleteFilter,
      getFilters: actionTypes.changeFilters,
    }),
    searchFilteredVac() {
      this.$emit("input", {
        city: this.selectedCity,
        job_title: this.jobTitle,
        job_type: this.jobType,
      });
      this.$emit("close");
      this.resetFilter(true);
    },
    resetSearchFiltered() {
      this.$emit("input", {
        city: null,
        job_title: null,
        job_type: null,
      });
      this.resetFilter(false);
      this.getFilters({
        city: null,
        job_title: null,
        job_type: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
